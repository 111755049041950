<template>
	<vs-prompt
		class="event-dialog"
		:title="printTitlePrompt"
		@accept="submitData()"
		:accept-text="data && data.id ? $t('Save Changes') : $t('Create Event')"
		:cancel-text="$t('Cancel')"
		:is-valid="isFormValid"
		:disabled-outside="true"
		:active.sync="isModalActiveLocal">
			<div class="p-6">
				<!-- Event Name -->
        <label class="text-title">
					{{ $t('Event Name') }}
				</label>
				<vs-input
					class="w-full pb-2"
					name="name"
					v-model="dataName"
					v-validate="'required'"
          data-vv-validate-on="blur"
				/>
				<span
					class="text-danger text-sm">
					{{ $t(errors.first('name')) }}
				</span>

      <div class="flex">
					<div class="mt-5 w-1/2 pb-2 mr-2">
						<label class="vs-select--label w-full">
							{{ $t('Start Date') }}
						</label>
						<div class="input-group relative">
						<flat-pickr
							class="date-event"
							:placeholder="$t('Start Event')"
							:config="configStartDateTimePicker"
							v-model="dataStartDate"
						/>
						<div class="icon-calendar-event">
							<feather-icon icon="CalendarIcon"/>
						</div>
					</div>
				</div>

				<!-- End Time -->
				<div class="mt-5 w-1/2 ml-2">
					<label class="vs-select--label">
						{{ $t('End Date') }}
					</label>
					<div class="input-group relative">
						<flat-pickr
            v-if="isShowEndDate"
							class="date-event"
							:placeholder="$t('End Event')"
							:config="configEndDateTimePicker"
							v-model="dataEndDate"
						/>
						<div class="icon-calendar-event">
							<feather-icon icon="CalendarIcon"/>
						</div>
					</div>
				</div>
			</div>
      <div class="text-gray">{{ userTimezone }}</div>

      <!-- Location -->
      <div class="mt-4">
        <label class="text-title">
            {{ $t('Location') }}
          </label>
        <vs-input
          class="w-full"
          name="item-name"
          v-model="dataLocation"
        />
      </div>

      <!-- event notes -->
      <div class="mt-4">
        <label class="text-title ">
          {{ $t('Event Notes') }}
        </label>
        <vs-textarea  class="mt-1" rows="4" v-model="dataEventNotes" :placeholder="$t('Write notes for pre event page')"/>
      </div>

      <!-- enable event registration -->
      <div class="mt-4 mb-4 flex justify-between items-center">
        <div class="font-medium">
          <div>{{ $t('Enable Event Registration') }}</div>
          <div class="font-normal text-small text-title">
            {{ $t('Display registration form for attendees') }}</div>
        </div>
        <div>
          <vs-switch :disabled="dataIsHybridEvent > 0" @click.stop="" color="success" v-model="dataIsEnableEventRegistration" />
        </div>
      </div>

      <!-- Hybrid Event -->
      <div class="mt-4 mb-4 flex justify-between items-center">
        <div class="font-medium">
          <div>{{ $t('Hybrid Event') }}</div>
          <div class="font-normal text-small text-title">
            {{ $t('Allow people to register to attend at the venue') }}</div>
        </div>
        <div>
          <vs-switch @click.stop="" color="success" v-model="dataIsHybridEvent" />
        </div>
      </div>

      <!-- Available seats -->
      <div v-if="dataIsHybridEvent" class="mt-4">
        <div>{{ $t('Available seats at the venue') }}</div>
        <vs-input v-filter-input
          style="width:80px"
          class="mr-2"
          type="number"
          name="item-name"
          v-model="dataSeats"
          @input="handleInput"
          @blur="handleBlur"
        />
      </div>

		</div>
	</vs-prompt>
</template>

<script>
/* eslint-disable camelcase */
import { mapGetters } from 'vuex';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getAxiosErrorMessage, duplicateVar } from '../lib/helper';
import eventsApi from '../api/event';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'EventModal',
  props: {
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    flatPickr,
  },
  data() {
    return {
      dataId: null,
      dataName: '',
      dataStartDate: '',
      dataEndDate: '',
      dataIsPaid: false,
      dataIsPublic: true,
      dataPrice: '',
      isSaving: false,
      types: [
        { value: 'annual', text: 'Annual' },
        { value: 'monthly', text: 'Monthly' },
      ],
      statusOptions: [
        { value: 'upcoming', text: 'Upcoming' },
        { value: 'active', text: 'Active' },
        { value: 'completed', text: 'Completed' },
      ],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      configStartDateTimePicker: {
        enableTime: true,
        altInput: true,
        altFormat: 'd F Y - h:i K',
        dateFormat: 'Y-m-d H:i',
        time_24hr: false,
      },
      configEndDateTimePicker: {
        enableTime: true,
        altInput: true,
        altFormat: 'd F Y - h:i K',
        dateFormat: 'Y-m-d H:i',
        time_24hr: false,
      },
      isShowEndDate: true,
      dataStatus: 'upcoming',
      dataLocation: '',
      dataEventNotes: '',
      dataIsEnableEventRegistration: false,
      dataIsHybridEvent: false,
      dataSeats: 1,
    };
  },
  watch: {
    isModalActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        this.setData();
      }
    },
    dataStartDate(newStartDate) {
      this.configEndDateTimePicker.minDate = dayjs(newStartDate).format('YYYY-MM-DDTHH:mm:ss');
      this.isShowEndDate = false;
      setTimeout(() => {
        this.isShowEndDate = true;
      }, 100);
      // Check if dataEndDate is greater than newStartDate
      if (newStartDate > this.dataEndDate) {
        // Set dataEndDate to newStartDate
        this.dataEndDate = newStartDate;
      }
      if (typeof this.dataEndDate !== 'string') {
        this.dataEndDate = newStartDate;
      }
    },
    dataIsHybridEvent() {
      if (!this.dataIsHybridEvent) this.dataSeats = 1;
      if (this.dataIsHybridEvent) this.dataIsEnableEventRegistration = true;
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    isModalActiveLocal: {
      get() {
        return this.isModalActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeModal');
          this.$validator.reset();
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any()
				&& this.dataName !== ''
				&& typeof this.dataStartDate === 'string'
				&& typeof this.dataEndDate === 'string'
      );
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    userTimezone() {
      const timezone = this.user.settings ? this.user.settings : '';
      const parseTimezone = JSON.parse(timezone);
      const finalTimezone = parseTimezone && parseTimezone.timezone ? parseTimezone.timezone : '';
      const now = dayjs(new Date());
      const finalUTC = `Active timezone is (UTC${dayjs.tz(now, finalTimezone).format('Z')})`;
      return finalUTC;
    },
    userTimezoneText() {
      const timezone = this.user.settings ? this.user.settings : '';
      const parseTimezone = JSON.parse(timezone);
      const finalTimezone = parseTimezone && parseTimezone.timezone ? parseTimezone.timezone : '';
      return finalTimezone;
    },
    userLang() {
      const lang = this.user.settings ? this.user.settings : '';
      const parseLang = JSON.parse(lang);
      const finalLanguage = parseLang && parseLang.language ? parseLang.language : '';
      return finalLanguage;
    },
    params() {
      const startTime = dayjs.utc(this.dataStartDate).format('YYYY-MM-DD HH:mm');
      const endTime = dayjs.utc(this.dataEndDate).format('YYYY-MM-DD HH:mm');
      const params = {
        id: this.dataId,
        name: this.dataName,
        start_date: startTime,
        end_date: endTime,
        timezone: this.userTimezoneText,
        language: this.data?.language || 'en',
        notes: this.dataEventNotes,
        is_enable_registration: this.dataIsEnableEventRegistration,
        is_hybrid: this.dataIsHybridEvent,
        location: this.dataLocation,
      };
      if (this.dataIsHybridEvent) params.seats = this.dataSeats;
      return params;
    },
    printTitlePrompt() {
      return Object.entries(this.data).length === 0 ? this.$t('Add New') : `${this.$t('Update')}  ${this.$t('Event')}`;
    },
  },
  methods: {
    handleInput(event) {
      // Check if the current value is 0, and update it to a minimum value if necessary
      if (parseInt(event) === 0) {
        // You can set it to 1 or any other minimum value that makes sense in your context
        this.dataSeats = 1;
      }
    },
    handleBlur(event) {
      // Check if the current value is 0, and update it to a minimum value if necessary
      if (!event.target.value) {
        // You can set it to 1 or any other minimum value that makes sense in your context
        this.dataSeats = 1;
      }
    },
    setData() {
      const {
        id,
        name,
        start_date,
        end_date,
        notes,
        is_enable_registration,
        seats,
        is_hybrid,
        location,
      } = duplicateVar(this.data);

      const startTime = dayjs.utc(start_date).format('YYYY-MM-DD HH:mm');
      const endTime = dayjs.utc(end_date).format('YYYY-MM-DD HH:mm');

      this.dataId = id;
      this.dataName = name;
      this.dataStartDate = startTime;
      this.dataEndDate = endTime;
      this.dataEventNotes = notes;
      this.dataIsEnableEventRegistration = is_enable_registration;
      this.dataSeats = seats;
      this.dataIsHybridEvent = is_hybrid;
      this.dataLocation = location;


      // init min date to previous data
      const formattedDate = dayjs(this.dataStartDate).format('YYYY-MM-DDTHH:mm:ss');
      const isAfterCurrentDate = dayjs(formattedDate).isAfter(dayjs());

      if (!isAfterCurrentDate) {
        this.configStartDateTimePicker.minDate = dayjs(this.dataStartDate).format('YYYY-MM-DDTHH:mm:ss');
        this.configEndDateTimePicker.minDate = dayjs(this.dataStartDate).format('YYYY-MM-DDTHH:mm:ss');
      } else {
        this.configStartDateTimePicker.minDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');
        this.configEndDateTimePicker.minDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');
      }

      this.initValues();
    },
    initValues() {
      if (this.data.id) return;
      this.configStartDateTimePicker.minDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');
      this.configEndDateTimePicker.minDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');
      this.dataId = null;
      this.dataName = '';
      this.dataEventNotes = '';
      this.dataIsHybridEvent = false;
      this.dataIsEnableEventRegistration = false;
      this.dataLocation = '';
      this.dataSeats = 1;

      const currentHour = dayjs().hour();
      const currentMinute = dayjs().minute();
      let defaultMorningTime;
      let defaultEveningTime;

      if (currentHour > 9 && currentHour <= 17) {
        defaultMorningTime = dayjs().set({
          hour: dayjs().hour(),
          minute: dayjs().minute(),
          second: 0,
          millisecond: 0,
        });
        defaultEveningTime = dayjs().hour(17).minute(0).second(0);
      }
      if (currentHour > 17 && currentHour <= 24) {
        defaultMorningTime = dayjs().set({
          hour: dayjs().hour(),
          minute: dayjs().minute(),
          second: 0,
          millisecond: 0,
        });
        defaultEveningTime = defaultMorningTime.clone().add(1, 'hour');
      }
      if (currentHour >= 0 && currentHour <= 9) {
        defaultMorningTime = dayjs().hour(9).minute(0).second(0);
        defaultEveningTime = dayjs().hour(17).minute(0).second(0);
      }

      if (currentHour === 9 && currentMinute > 0) {
        defaultMorningTime = dayjs().set({
          hour: dayjs().hour(),
          minute: dayjs().minute(),
          second: 0,
          millisecond: 0,
        });
      }

      this.dataStartDate = defaultMorningTime.format('YYYY-MM-DD HH:mm');
      this.dataEndDate = defaultEveningTime.format('YYYY-MM-DD HH:mm');
    },
    submitData() {
      this.isSaving = true;
      const notifTitle = this.$t('Event');
      const isUpdate = !!this.dataId;
      const params = this.params;
      const callback = (response) => {
        const item = response.data;
        const message = response.message;
        this.$emit('add', item);
        this.$emit('closeModal');
        this.initValues();
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSaving = false;
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSaving = false;
      };

      if (isUpdate) {
        eventsApi.update(this.dataId, params, callback, errorCallback);
      } else {
        eventsApi.create(params, callback, errorCallback);
      }
    },
  },
};
</script>
<style lang="scss">
.date-event{
	width: 100%;
	padding: 10px;
	padding: .7rem;
	font-size: 1rem;
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, .2);

	&.active {
		border: 1px solid #0154C7;
	}
}
.date-picker{
	width: 100%;
}
.sub-title-public{
	color: grey;
	font-size: 13px;
}
.icon-calendar-event{
	color: grey;
	position: absolute;
	top: 0;
	margin: 0.3em;
	right: 0;
}
</style>
